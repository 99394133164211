import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import * as styles from './templates.module.scss';
import { calculateTimeReading } from '../helpers';
import {
  Breadcrumb, Header, Content, Articles,
} from '../components/media';

const options = {
  renderNode: {
    'embedded-asset-block': (node) => node.data?.target?.sys?.id || '',
  },
};

function generateMediaTag(reference) {
  const fileExtension = reference.file.contentType;
  if (fileExtension === 'video/mp4' || fileExtension === 'video/avi' || fileExtension === 'video/mov') {
    return `<video src=${`https:${reference.file.url}`} controls></video>`;
  }
  return `<img src=${`https:${reference.file.url}`} alt=${`${reference.file.fileName}`}>`;
}

function MediaDetailTemplate({ data }) {
  const {
    id, title, date, detailImage, content,
  } = data.contentfulPageMediaDetail;
  let text = content?.raw
    ? documentToHtmlString(JSON.parse(content?.raw), options)
    : content;
  const estimatedTimeReading = calculateTimeReading(text);

  content.references.forEach((reference) => {
    const mediaTag = generateMediaTag(reference);
    text = text.replace(reference.contentful_id, mediaTag);
  });

  let moreArticles = data.allContentfulPageMediaDetail?.nodes;
  moreArticles = moreArticles.filter((item) => item.id !== id);
  // Just 5 elements are show at maximum
  moreArticles = moreArticles.slice(0, 5);

  return (
    <div className={styles.mediaContainer}>
      <div className={styles.mediaDetailHeaderContainer}>
        <Breadcrumb text={`Media > ${title}`} />
        <Header
          title={title}
          date={date}
          estimatedTimeReading={estimatedTimeReading}
          image={detailImage}
        />
      </div>
      <Content contentRichText={text} />
      <Articles
        title={data.pageData?.mediaPageSubtitle}
        articles={moreArticles}
        showTitle
      />
    </div>
  );
}

export const query = graphql`
query ($uri: String) {
  contentfulPageMediaDetail(uri: {eq: $uri}){
      id
      title
      uri
      date
      seo {
        metaDescription
        pageTitle
      }
      detailImage {
        title
        image {
          gatsbyImage(width: 1000, placeholder: NONE, layout: FULL_WIDTH)
        }
        altText
      }
      content {
        raw
        references {
          contentful_id
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
    allContentfulPageMediaDetail(limit: 6, sort: {fields: date, order: DESC}) {
      nodes {
        id
        title
        uri
        description {
          description
        }
        date
        seo {
          metaDescription
          pageTitle
        }
        detailImage {
          title
          image {
            gatsbyImage(width: 260, placeholder: NONE, layout: FIXED)
          }
          altText
        }
      }
    }
    pageData: contentfulPageMediaConfig(id: {eq: "2db7e1ea-f396-5883-948a-b2cac81d7916"}) {
      mediaPageSubtitle
    }
  }
`;

MediaDetailTemplate.propTypes = {
  data: object,
};

MediaDetailTemplate.defaultProps = {
  data: {},
};

export default MediaDetailTemplate;
export { Head } from '../components/seo/seo';
